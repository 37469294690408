import React from "react";
import { Link } from "gatsby";
import { Row, Col, Container, Breadcrumb, Image, Figure } from "react-bootstrap";

import Layout from "../../components/layout";
import Seo from "../../components/seo";

import LuteinVidMp4 from "./../../images/lutein-vijdame-header.mp4";
import LuteinVidWebm from "./../../images/lutein-vijdame-header.webm";
import LuteinVidThumb from "./../../images/lutein-vijdame-header-thumb.jpg";

import lutShield from "./../../images/lutein-specialisti-VESKA_STOYANOVA.jpg";
import warningSign from "./../../images/dr-Stavrev-lutein.jpg";

import lutein from "./../../images/lutein-wet-wipe-promo-no1.jpg";

const LuteinVijdame = () => (
  <Layout backButton={true} pageInfo={{ pageName: "lutein-vijdame" }}>
    <Seo title="Как да виждаме по-добре?" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/articles">СТАТИИ</Link>
            </li>
            <Breadcrumb.Item active>КАК ДА ВИЖДАМЕ ПО-ДОБРЕ?</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <video
            className="w-100"
            loop={true}
            autoPlay="autoplay"
            poster={LuteinVidThumb}
            muted
          >
            <source src={LuteinVidMp4} type="video/mp4" />
            <source src={LuteinVidWebm} type="video/webm" />
            This browser does not support the HTML5 video element.
          </video>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={12} className="mb-4">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Как да виждаме по-добре?
          </h1>
          <h2 className="font-light ">
            <em>Специална разработка помага и в най-заплетените случаи</em>
          </h2>
        </Col>
        <Col sm={12}>
          <p>
            Бързо връщане на нормалното зрение постига специална разработка,
            която е дело на екип от световни капацитети в медицината. За
            създаването ѝ експертите приложили високотехнологично фармацевтично
            решение, което усилва нейната ефективност. Иновацията, чието име у
            нас е ЛУТЕИН КОМПЛЕКС ПЛЮС, помага дори при заплетените случаи.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <h4>3 пъти по-мощно</h4>
          <p>
            За извънредния ѝ потенциал основен принос има патентованата
            технология LICAPSTM (течност в капсула). Тя изисква да се използват
            само вещества в маслен вид, а за течностите се знае, че се усвояват
            в пъти по-добре в сравнение със сухите субстанции. Нещо повече –
            лабораторен тест показва, че LICAPSTM продуктите постигат над 90%
            усвояване, докато при таблетките процентът е едва 30%. Това обяснява
            защо специалният продукт е по-мощен от другите формули, познати
            досега.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col >
          <h4>Орлово зрение</h4>

          <p>
            Освен усвояването водеща роля за изразителните способности на
            продукта играе и неговият състав. Той включва ключови за здравето на
            очите природни вещества, които отговарят за правилното функциониране
            на макулата, ретината, очната лигавица и кръвоносните съдове.
            Уникалното обаче е друго – чрез ЛУТЕИН КОМПЛЕКС ПЛЮС медицината за
            първи път ни дава свободен достъп до силата на маслени
            суперекстракти в най-чистата им форма. Сред тях са маслен лутеин и
            маслен зеаксантин, чиито качества значително превъзхождат
            възможностите на конвенционалните форми.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <h4>Помага при катаракта</h4>
          <p>
            Не можем да спрем годините, но вече можем да ограничим техните
            негативни ефекти върху зрението. Катарактата е водеща причина за
            слепота, а тя атакува всички след 50-годишна възраст. Лечението ѝ
            трябва да започне навреме и да обхваща всички аспекти на проблема.
            Като ефективно средство за борба с катарактата в моята практика се
            наложи природният продукт ЛУТЕИН КОМПЛЕКС ПЛЮС. Той представлява
            функционална комбинация от шестте най-важни за доброто зрение
            натурални вещества – лутеин, зеаксантин, черна боровинка, вит. С,
            вит. Е и цинк. Уникалното обаче е друго – единствено в него
            субстанциите са във вид на суперекстракти. Те се усвояват пълноценно
            и тялото се възползва от оздравителната им мощ в максимална степен.
            Резултатите са видими скоро след началото на приема, а пациентите
            съобщават за значително подобрение в зрението. От медицинска гледна
            точка важно е, че редовният прием на ЛУТЕИН КОМПЛЕКС ПЛЮС в доза от
            само 1 капсула на ден забавя прогресията на катарактата и осигурява
            на пациентите нормален живот.
          </p>
          <p>
            <em>
              Гл. асистент д-р Владимир Ставрев, дм, FEBO, УМБАЛ “Св. Георги”
              ЕАД, гр. Пловдив, Катедра по Очни болести, МУ Пловдив
            </em>
          </p>
        </Col>
        <Col sm={3} xs={5}>
          <Figure className="mr-3 mb-3 w-100 float-left">
            <Figure.Image
              alt="Гл. асистент д-р Владимир Ставрев"
              src={warningSign}
            />
            <Figure.Caption>
              Гл. асистент д-р Владимир Ставрев, дм, FEBO, УМБАЛ “Св. Георги”
              ЕАД, гр. Пловдив, Катедра по Очни болести, МУ Пловдив
            </Figure.Caption>
          </Figure>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col md={3} xs={12}>
          <Figure className="ml-3 mb-3 w-100 float-right ">
            <Figure.Image
              alt="Веска Стоянова, 85 г., Велинград"
              src={lutShield}
            />
            <Figure.Caption>Веска Стоянова, 85 г., Велинград</Figure.Caption>
          </Figure>
        </Col>
        <Col>
          <h4>На 85 чета без очила</h4>
          <p>
            Аз съм на 85 години и от петнадесет години пия непрекъснато ЛУТЕИН
            КОМПЛЕКС ПЛЮС. Започнах, когато докторът ми откри зачатъци на
            замъглено зрение и много се уплаших. Той ми каза да пия ЛУТЕИН
            КОМПЛЕКС ПЛЮС всеки ден по 1 капсула. И сега да се похваля, мъглата
            изчезна, не нося очила, чета си програмата без очила. Имам две
            дъщери и трима внуци. Те нали с компютри работят, сега и те пият
            ЛУТЕИН КОМПЛЕКС ПЛЮС и им помага. Много съм щастлива, че успях да
            помогна и на себе си, и на тях.
          </p>
          <p>
            <em>Веска Стоянова, 85 г., Велинград</em>
          </p>
        </Col>
      </Row>
      

      <Row className="product-container">
        <Col md={{ span: 10, offset: 1 }}>
          <Link to="/lutein-complex">
            <Image className="w-100" src={lutein} />
          </Link>
        </Col>
      </Row>
      <Row className="product-container">
        <Col>
          <p>
            Вземете сега ЛУТЕИН КОМПЛЕКС ПЛЮС с подарък мокри кърпички за очила,
            в най-близката до вас аптека.
          </p>
          <p>
            Телефон за консултация и поръчка:{" "}
            <a href="tel:0876181000">0876 18 1000</a>
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <h4>
            <Link to="/lutein-complex">
              Повече информация за ЛУТЕИН КОМПЛЕКС ПЛЮС можете да намерите тук.
            </Link>
          </h4>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <p>
            <em>
              *Най-продаван продукт в категория „Витамини и добавки за очи“ за
              2020 г. по данни на IQVIA.
            </em>
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <p>
            Да не се превишава препоръчваната дневна доза! Продуктът да не се
            използва като заместител на разнообразното хранене!
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default LuteinVijdame;
